import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap'; // Importa el componente Modal de Reactstrap o la biblioteca que estés utilizando

function ObtenerEventos(props) {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [showModal, setShowModal] = useState(false); // Estado para mostrar/ocultar la modal

  useEffect(() => {
    // Realizar la solicitud POST una vez que el componente esté montado
    fetchData();
  }, []); // El segundo argumento de useEffect es un array vacío, lo que significa que este efecto se ejecutará solo una vez, después de que el componente se monte

  const fetchData = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const idIde = searchParams.get('ide');
    const decIde = decodeURIComponent(idIde);
    const idIdi = searchParams.get('idi');
    const decIdi = decodeURIComponent(idIdi);


  
    const url = `https://eventoservice2-7e2a65d34d88.herokuapp.com/Event/getevent?id_evento=${encodeURIComponent(decIde)}`;
    const urlinv2 = `https://eventoservice2-7e2a65d34d88.herokuapp.com/Invited/getinvitadoId?id_invitado=${encodeURIComponent(decIdi)}`;
  
    fetch(urlinv2, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      setResponseData(data);
      props.handleData(data);
      setLoading(false); // Cambiar el estado de carga a falso cuando se recibe la respuesta
    })
    .catch(error => {
      console.error('Error al enviar la solicitud:', error);
      // Manejar el error aquí si es necesario
      setLoading(false); // Asegurarse de cambiar el estado de carga en caso de error
    });
  };

  // Mostrar la modal cuando loading es true y responseData es null
  useEffect(() => {
    if (loading && responseData === null) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [loading, responseData]);

  // Cuando los datos están cargados y responseData ya no es null, muestra los datos
  return (
    <>
      <Modal isOpen={showModal}>
        <ModalBody>
          <p>Por favor espere un momento ...</p>
        </ModalBody>
      </Modal>
      
      {/* Contenido principal del componente */}
      
    </>
  );
}

export default ObtenerEventos;
