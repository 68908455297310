import React, { useState, useEffect } from 'react';
import "assets/css/SwComponent.css";
import yesico from 'assets/img/yes.png'; 
import noico from 'assets/img/no.png'; 

function SwitchButtons({ selOptionValue, confirmaprecstring }) {
  const [selected, setSelected] = useState(confirmaprecstring);

  useEffect(() => {
    setSelected(confirmaprecstring);
  }, [confirmaprecstring]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);
    selOptionValue(value); // Actualiza el valor en el componente padre
    console.log("confirmaprecstring-sw ---------------> " + value);
  };

  return (
    <div className="button-container">
      <input
        type="radio"
        id="NO"
        name="choice"
        value="NO"
        checked={selected === 'NO'}
        onChange={handleChange}
        className="radio-input"
      />
      <label
        htmlFor="NO"
        className={`button-label ${selected === 'NO' ? 'active-no' : ''}`}
      >
        <img src={noico} alt="Icon" style={{ width: '30px', marginRight: '8px' }} />
        NO
      </label>

      <input
        type="radio"
        id="SI"
        name="choice"
        value="SI"
        checked={selected === 'SI'}
        onChange={handleChange}
        className="radio-input"
      />
      <label
        htmlFor="SI"
        className={`button-label ${selected === 'SI' ? 'active-si' : ''}`}
      >
        <img src={yesico} alt="Icon" style={{ width: '30px', marginRight: '8px' }} />
        SI
      </label>
    </div>
  );
}

export default SwitchButtons;
