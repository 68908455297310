import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import masicon from 'assets/img/masicon.png'; 
import menosicon from 'assets/img/menosicon.png'; 
import changeicon from 'assets/img/changeicon.png'; 

export var ItTotPrice;
export var ItCantTotal;

function GetItemTotPrice(ItPr) {
  ItTotPrice = ItPr;
  return ItPr;
}

function GetItemCantTotal(ItCntTot) {
  ItCantTotal = ItCntTot;
}

function IncDec({ cantModifInvit, cantInviConfChg,cantInviDB }) {

  function QuantityCounter() {
    const [quantity, setQuantity] = useState(Number(cantInviConfChg)); // Iniciar con cantInviConfBD
    console.log("inicia con  ---------------> " + quantity);

    useEffect(() => {
      // Esto se ejecutará cada vez que quantity cambie
      cantModifInvit(quantity);
      console.log("cambia......  ---------------> "+quantity);
    }, [quantity]);

    const incrementQuantity = () => {
      if (quantity < Number(cantInviDB)) {
        setQuantity((prevQuantity) => prevQuantity + 1);
        console.log("suma si es menor a cantInviConfBD ---------------> " + quantity);
      }
    };

    const decrementQuantity = () => {
      if (quantity > 0) {
        setQuantity((prevQuantity) => prevQuantity - 1);
        console.log("resta si es mayor q 0  ---------------> " + quantity);
      }
    };

    GetItemCantTotal(quantity);
    
    return (
      <div>
        <div>
          <p
            style={{
              padding: '1%',
              justifyContent: 'left',
              alignItems: 'left',
              fontWeight: 'bold',
              fontSize: '1rem',
              width: '100%',
              color: 'white',
              backgroundColor: '#8f857c',
              borderRadius: '20px'
            }}
          >
            <img src={changeicon} alt="Icon" style={{ width: '30px', marginRight: '8px' }} />
            Cantidad de confirmados
          </p>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '60%',
          margin: 'auto',
          color: 'black'
        }}>
          <Button 
            onClick={decrementQuantity}
            style={{
              padding: '1%',
              display: 'flex',
              width: '100%',
              border: '0px solid #ffffff',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              backgroundColor: 'rgba(255, 255, 255, 0)'
            }}
          >
            <img src={menosicon} alt="Icon" style={{ width: '50px' }} />
          </Button>
          
          <p style={{
            padding: '1%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '0px solid #ffffff',
            fontWeight: 'bold',
            fontSize: '1.5rem'
          }}>
            <div style={{ paddingTop: '10px' }}>{quantity}</div>
          </p>
          
          <Button 
            onClick={incrementQuantity}
            style={{
              padding: '1%',
              display: 'flex',
              border: '0px solid #ffffff',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              backgroundColor: 'rgba(255, 255, 255, 0)'
            }}
          >
            <img src={masicon} alt="Icon" style={{ width: '50px' }} />
          </Button>
        </div>
      </div>
    );
  }

  return <QuantityCounter />;
}

export default IncDec;
