import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ActualizarInvitados({ idRestalim,asistebd,asisteus, cantinvibd,cantinvius,idinvitado,detRestAlim,handleData, execute }) {
  const [responseData, setResponseData] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [modal, setModal] = useState(false);

  // Función para evaluar asistencia
  const convertAsisteToBoolean = (asisteus) => {
    return asisteus === ''  ? asistebd : asisteus;
  };
  const booleanAsiste = convertAsisteToBoolean(asisteus); // Convertir asiste a booleano

  //funcion para evaluar cantidad de invitados
  const evaluacantinv = (cantinvius) => {
    return (cantinvius === '' || cantinvius === undefined) ? cantinvibd : cantinvius; 
  };
  const evalci = evaluacantinv(cantinvius);

  //funcion para evaluar la restriccion alimentaria
  const evalcad = idRestalim === '' ? '0' : idRestalim;
  const ralim = parseInt(evalcad, 10); // Convierte '123' a 123
  

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    if (execute) {
      fetchData();
    }
  }, [execute]);

  const fetchData = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const idIde = searchParams.get('ide');
    const decIde = decodeURIComponent(idIde);

    const idRest = searchParams.get('idrestalimentaria');

    const idIdi = searchParams.get('idi');
    const decIdi = decodeURIComponent(idIdi);

   
    
    //console.log('aqui convierte booleanAsiste   ->' + booleanAsiste);

    console.log('decide->' + decIde);
    console.log('decidi->' + decIdi);

    //const actInv = `https://eventoservice-85f83b7c1adf.herokuapp.com/Invited/updateInvitado?asiste=${encodeURIComponent(booleanAsiste)}&cantidad=${encodeURIComponent(evalci)}&id_invitado=${encodeURIComponent(decIdi)}&idrestalimentaria=${encodeURIComponent(ralim)}`;
    const actInv = `https://eventoservice2-7e2a65d34d88.herokuapp.com/Invited/updateInvitado?asiste=${encodeURIComponent(booleanAsiste)}&cantidad=${encodeURIComponent(evalci)}&id_invitado=${encodeURIComponent(idinvitado)}&idrestalimentaria=${encodeURIComponent(ralim)}&detalles=${encodeURIComponent(detRestAlim)}`;
    
    fetch(actInv, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(response => response.json())
      .then(data => {
        setResponseData(data);
        handleData(data);
        setSuccessMessage('Registro guardado con éxito');
        //toggleModal(); // Mostrar el modal
        setModal(true);
        console.log('dataaaa   ->' + data);
      })
      .catch(error => {
        console.error('Error al enviar la solicitud:', error);
      });
  };

  return (
    <Modal isOpen={toggleModal}>
    <ModalBody>
      <p>Por favor espere, guardando la modificación...</p>
    </ModalBody>
  </Modal>
  );
}

export default ActualizarInvitados;
