
import ObtenerInvitado from 'components/Headers/EPEvento.js';
import React, { useState, useEffect } from 'react';

function DietaryRestrictions({ restrictions, setRestrictions }) {
  const [menuData, setMenuData] = useState([]); // Estado para almacenar los datos de menú

  // Función para pasar los datos de menú recibidos a este componente y almacenarlos en el estado
  const handleData = (data) => {
    setMenuData(data);
  };

  // Efecto para actualizar restricciones cuando menuData cambia
  useEffect(() => {
    const menuArray = Object.values(menuData); // Convierte el JSON a un array de valores
    menuArray.forEach(menu => {
      switch(menu.idRestAlimentaria) {
        case 1:
          setRestrictions(prevState => ({ ...prevState, celiac: true }));
          break;
        case 2:
          setRestrictions(prevState => ({ ...prevState, vegan: true }));
          break;
        case 3:
          setRestrictions(prevState => ({ ...prevState, hypertensive: true }));
          break;
        case 12:
          setRestrictions(prevState => ({ ...prevState, celiac: true, vegan: true }));
          break;
        case 13:
          setRestrictions(prevState => ({ ...prevState, celiac: true, hypertensive: true }));
          break;
        case 23:
          setRestrictions(prevState => ({ ...prevState, vegan: true, hypertensive: true }));
          break;
        case 123:
          setRestrictions(prevState => ({ ...prevState, celiac: true, vegan: true, hypertensive: true }));
          break;
        // Agrega más casos según sea necesario
        default:
          break;
      }
    });
  }, [menuData, setRestrictions]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setRestrictions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div>
      
      <ObtenerInvitado handleData={handleData} />
      <div>
      <label style={{ paddingBottom: '3%',paddingTop: '2%' }}>
        <img alt="..." src={require("assets/img/celiac-logo.png")} style={{ width: '40px', height: '40px' }} />
        <input
          type="checkbox"
          name="celiac"
          checked={restrictions.celiac}
          onChange={handleCheckboxChange}
        />
         &nbsp;Celiaco
      </label>
      </div>
      <div>
      <label style={{ paddingBottom: '3%'}}>
        <img alt="..." src={require("assets/img/vegan.png")} style={{ width: '40px', height: '40px' }} />
        <input
          type="checkbox"
          name="vegan"
          checked={restrictions.vegan}
          onChange={handleCheckboxChange}
        />
         &nbsp;Vegetariano
      </label>
      </div>
      <div>
      <label style={{ paddingBottom: '3%' }}>
        <img alt="..." src={require("assets/img/hipicon.png")} style={{ width: '40px', height: '40px' }} />
        <input
          type="checkbox"
          name="hypertensive"
          checked={restrictions.hypertensive}
          onChange={handleCheckboxChange}
        />
         &nbsp;Hipertenso
      </label>
      </div>
    </div>
  );
}

export default DietaryRestrictions;
