/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer >
      {/* Publicidad Qdev*/}
      
      <div style = {{
                      backgroundColor: 'rgba(128, 128, 128, 0.5)', 
                      position:'fixed',bottom:'0',
                      width: '100%',
                      zIndex:'50',
                      display: 'flex',
                      paddingTop:'6px'}}>
            <div>
            
              <ul style = {{listStyleType: 'none'}}>
                <li>
                  <a
                    href="https://www.instagram.com/qualitydevelopment_"
                    target="_blank"
                  >
                    <img alt="..." className="qdev-logo" src={require("assets/img/qdev.png")} 
                    style={{height: '34px',width: '34px',}}></img>
                  </a>
                </li>
              </ul>
            
            </div>
            {/*<div>
            
              <ul style = {{listStyleType:'none'}}>
                <li>
                  <a
                    href="https://www.instagram.com/elpatronburger_"
                    target="_blank"
                  >
                    <img alt="..." className="qdev-logo" src={require("assets/img/elpatronlogo.png")} 
                    style={{height: '34px',width: '34px',}}></img>
                  </a>
                </li>
              </ul>
            
            </div>*/}

            
  
      </div>
      
    </footer>
  );
}

export default DarkFooter;
