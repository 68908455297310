
import { Form, FormGroup, Label, Input, Button, Col } from "reactstrap";
import PadreDelRadioButton from "views/examples/PadreDelRadioButton";
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import IncrementDecrementBtn from "views/examples/IncDecBtn.js";
import IncDec from 'views/examples/IdBtn.js';
import SwComponent from "views/examples/SwComponent.js";
import enviaricon from 'assets/img/enviaricon.png'; 
import cancelico from 'assets/img/cancelico.png'; 
import inviteicon from 'assets/img/inviteicon.png'; 
import likeicon from 'assets/img/likeicon.png'; 
import restalimicon from 'assets/img/restalimicon.png'; 
import confipr from 'assets/img/confipr.png'; 
import enviarcon from 'assets/img/enviarcon.png'; 
import ActualizarInvitados from 'components/Headers/EPESetEvento.js';
import { useNavigate } from 'react-router-dom';
import "assets/css/SwComponent.css";

function FormConf() {
  
  const location = useLocation();
  const {
    fonturlinv,
    nombreinvitado,
    cantidadinviconf,
    cantidadinvitados,
    confirmaprecstring, 
    selectedoption,
    setselectedoption,
    setidinvitado,
    setdetalles
  } = location.state || {};
  //Actualizar  invitados
  //const [count, setCount] = useState(cantidadinviconf);
  
  
  const [responseData, setResponseData] = useState(null);
  const [idRestalim, setIdRestalim] = useState(null);
  const [cantidad, setCantidad] = useState(null);
  const [asiste, setAsiste] = useState(null);
  const [execute, setExecute] = useState(false);
  const [selectedValue, setSelectedValue] = useState(confirmaprecstring);
  const [cantModifInv, setCantModifInv] = useState(cantidadinviconf);
  const [detRestAlim, setDetRestAlim] = useState(setdetalles);
  
  const handleData2 = (data) => {
    setResponseData(data);
    setExecute(false); // Resetear después de la ejecución
  };
  console.log("confirma invitados  desde formconf  ---------------> "+cantModifInv);
  console.log("confirmaprecstring-sw desde formconf  ---------------> "+selectedValue);
  console.log("restriccion alimentaria  ---------------> "+detRestAlim);
  const handleClick = () => {
    //navigate('/');
    setExecute(true);
    confirmaform();
  };
  const confirmaform = ()=>{
    const confform = window.alert('Gracias por su confirmación !');
  }
  const handleSubmit = (event) => {
    event.preventDefault(); // Evita que el formulario se recargue
    // Aquí puedes manejar el guardado de los datos, por ejemplo:
  };

  {/*const confirmaprecString = confirmaprec;
  const [selectedOption, setSelectedOption] = useState('');*/}
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        position: "relative",
      }}
    >
      <link href="https://fonts.googleapis.com/css2?family=Dancing+Script" rel="stylesheet" />
      <div
        style={{
          width: "26rem",
          padding: "2rem",
          backgroundImage: "url(" + require("assets/img/frmbackground.png") + ")",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Form onSubmit={handleSubmit}>
            <div for="form4Example1" style = {{padding: '2%',fontFamily:fonturlinv ,fontWeight:'bold', fontSize:'2em',display: 'flex',height: '100%',justifyContent: 'center',alignItems: 'center',textalign:'center'}}>
              {nombreinvitado}
            </div>
          <FormGroup>   
            {/*confirmacion de prescencia SI NO */}
            <Label for="form4Example1" style = {{color:'white',backgroundColor:'#8f857c',borderRadius: '20px',fontWeight:'bold'}}>
              <img src={confipr} alt="Icon" style={{ fontFamily: '"Dancing Script", cursive',fontWeight: 'bold',width: '30px', marginRight: '8px' }} />
                Confirmar presencia&nbsp; &nbsp;&nbsp;&nbsp;
            </Label>
            <SwComponent selOptionValue={setSelectedValue} confirmaprecstring={selectedValue} />
            <Label for="form4Example2" style = {{color:'#443633',fontWeight: 'bold'}}>
                <img src={inviteicon} alt="Icon" style={{ width: '30px', marginRight: '8px' }} />Invitados:&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                <Label for="cantinv" style={{ color:'#443633',fontWeight: 'bold',paddingTop:'20px',justifyContent: 'space-between',alignItems: 'center',}}>
                  &nbsp; &nbsp;{cantidadinvitados}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Label>
            </Label>
          </FormGroup>

          <FormGroup>
            <Label for="form4Example2" style = {{color:'#443633',fontWeight: 'bold'}}>
                <img src={likeicon} alt="Icon" style={{ width: '30px', marginRight: '8px' }} />Confirmados:&nbsp;&nbsp;
                <Label for="cantinv" style={{ color:'#443633',fontWeight: 'bold',justifyContent: 'space-between',alignItems: 'center',}}>
                  &nbsp; &nbsp;{cantidadinviconf}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Label>
            </Label>
          </FormGroup>

          <FormGroup>
          
            {/*cambiar cantidad de confirmados*/}
            <div className="container"><IncDec  
                                                cantModifInvit = {setCantModifInv} // cantidad invitados confirmados
                                                //cantidadinvitados = {cantModifInv} // cantidad invitados modificados
                                                cantInviConfChg={cantModifInv}
                                                cantInviDB = {cantidadinvitados}
                                                /></div>
        
          </FormGroup>

          <FormGroup>
            <Label for="form4Example3" style = {{color:'white',backgroundColor:'#8f857c',borderRadius: '20px',fontWeight:'bold'}}><img src={restalimicon} alt="Icon" style={{ width: '30px', color:'white',marginRight: '8px' }} />Restricción alimentaria&nbsp;&nbsp;&nbsp;</Label>
            <Input 
                  type="textarea" 
                  id="form4Example3" 
                  rows="4" 
                  value={detRestAlim}
                  onChange={(e) => setDetRestAlim(e.target.value)}
                  style = {{borderRadius: '10px'}} />
          </FormGroup>

          <FormGroup check row>
            <Col className="d-flex justify-content-center mb-4">
              <Input type="checkbox" id="form4Example4" defaultChecked />
              <Label check for="form4Example4" className="ms-2">
              
              </Label>
            </Col>
          </FormGroup>
          <div style = {{display: 'flex',justifyContent: 'space-between',alignItems: 'center',textalign:'center'}}>
            <Button 
            color="primary" 
            block 
            onClick={handleClick} 
            type="submit"
            style={{ 
              color:'white',
              backgroundColor: '#443633', 
              height: 'auto', 
              alignItems: 'center',
              justifyContent: 'center',
              textalign:'center'
            }}>
            <img src={enviarcon} alt="Icon" style={{ width: '30px', marginRight: '8px'}} />
            
              Enviar
            </Button> 

            {execute && (
                <ActualizarInvitados
                    idRestalim={0}
                    asistebd={confirmaprecstring}
                    asisteus={selectedValue}
                    cantinvibd = {cantidadinvitados}
                    cantinvius ={cantModifInv}
                    idinvitado ={setidinvitado}
                    detRestAlim = {detRestAlim}
                    handleData={handleData2}
                    execute={execute}
                    //function ActualizarInvitados({ idRestalim, cantidad, asistebd,asisteus, cantinvibd,cantinvius,handleData, execute }) 
                />
            )}
            
            <Button color="primary" block style={{ 
              color:'white',
              backgroundColor: '#443633', 
              height: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
              textalign:'center'
              }}
              onClick={() => navigate(-1)}
              >
            
            <img src={cancelico} alt="Icon" style={{ width: '30px',marginRight: '8px' }} />
              Cerrar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default FormConf;
