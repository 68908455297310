/*eslint-disable*/
//import React from "react";
import React, { useState, useEffect, useRef } from 'react';
import { Container } from "reactstrap";
import Modal from 'react-modal';
import "assets/css/fontefect.css";
import "assets/css/fuentes.css";
import { Helmet } from 'react-helmet';
//import forminvitado from "./formularioinvitado.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import PadreDelRadioButton from "views/examples/PadreDelRadioButton";
import CheckDietas from 'views/examples/CheckDietas';
import SelectedRestrictions from './SelectedRestrictions';
import ObtenerInvitado from 'components/Headers/EPEvento.js';
import ActualizarInvitados from './EPESetEvento.js';
//import IncDec from './IDBTn.js';
import { ItTotPrice, ItCantTotal } from './IDBTn.js';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import { Alert} from "reactstrap";
import { toZonedTime } from 'date-fns-tz';
import moment from 'moment';
import 'moment-timezone';
import CronGift from './CronGift.js';
//import ObtenerInvitado from './EPEvento.js'
import {
    Button,
    Card,
    CardImg,
    NavItem,
    NavLink,
    Nav,
    Row,
    Col,
    UncontrolledTooltip,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";

import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale'; // Importar el locale en español
import CardPage from "views/examples/CardPage.js";
//import "assets/css/new-card-t.css";


//import { DateTime } from 'luxon';


Modal.setAppElement('#root');
// reactstrap components . 
// core components . --------
const confirmarYAbrirEnlaceGoogleMaps = () => {
const confirmacion = window.confirm('¿Desea abrir la localización en Google Maps?');
if (confirmacion)   {
const latitud = '-29.823311816887777';  // Reemplazar con la latitud deseada
const longitud = '-58.08350068684602';  // Reemplazar con la longitud deseada
const url = `https://www.google.com/maps/search/?api=1&query=${latitud},${longitud}`;
window.open(url, '_blank');
}
};
const confirmarYAbrirEnlaceGoogleMaps2 = (latitudSE,longitudSE) => {
const confirmacion = window.confirm('¿Desea abrir la localización en Google Maps?');
if (confirmacion)   {
//const latitud = '-29.7864836';  // Reemplazar con la latitud deseada
//const longitud = '-58.0641993';  // Reemplazar con la longitud deseada
    
const latitud = latitudSE;  // Reemplazar con la latitud deseada
const longitud = longitudSE;  // Reemplazar con la longitud deseada
const url = `https://www.google.com/maps/search/?api=1&query=${latitud},${longitud}`;
window.open(url, '_blank');
}
};
function IndexHeader() {

//-------------------------------------------------------------------------------------------
// funciones de lectura del ep de invitado y definicion de constantes 
// ------------------------------------------------------------------------------------------
const [menuData, setMenuData] = useState([]); // Estado para almacenar los datos de menú
// Función para pasar los datos de menú recibidos a este componente y almacenarlos en el estado
const handleData = (data) => {setMenuData(data);};
//const filteredMenuData = menuData.filter(menu => menu.id === menu.id);
const menuArray = Object.values(menuData); // Convierte el JSON a un array de valores
//const filteredMenuData = menuArray.filter(menu => menu.id === menu.id);
const filteredMenuData = [];
for (const key in menuData) {
if (menuData.hasOwnProperty(key)) {
const menu = menuData[key];
if (menu.id === menu.id) {
filteredMenuData.push(menu);
}
}
}
const nombreInvitado = filteredMenuData.map(menu => menu.nombreInvitado);
const cantidadInvitado2 = filteredMenuData.map(menu => menu.cantidad);
const cantidadInviConf = filteredMenuData.map(menu => menu.cantidadConfirmada);
const confirmaprec = filteredMenuData.map(menu => menu.asiste);
//const idevento = filteredMenuData.map(menu => menu.idEvento);
const idevento = filteredMenuData.map(menu => menu.idEvento)[0];
const idinvitado = filteredMenuData.map(menu => menu.id);
const detalles = filteredMenuData.map(menu => menu.detalles);
const idinvitadotp = filteredMenuData.map(menu => menu.id)[0];
const idrestal = filteredMenuData.map(menu => menu.idRestAlimentaria);



//-------------------------------------------------------------------------------------------
// funciones de lectura del EP EVENTO
//-------------------------------------------------------------------------------------------
const [menuDataE, setMenuDataE] = useState([]); // Estado para almacenar los datos de menú
const menuArrayE = Object.values(menuDataE); // Convierte el JSON a un array de valores
const menuE = menuDataE;
const filteredMenuDataE = [];
filteredMenuDataE.push(menuE);
const nombreTipoEvento = filteredMenuDataE.map(menuE => menuE.nombreTipoEvento);
//color texto nombre del evento
const colortxtnomtev = filteredMenuDataE.map(menuE => menuE.colorTxtNomTEv);
//color texto sub eventos
const colortxtsubevento = filteredMenuDataE.map(menuE => menuE.colorTxtSubEvento);
//color texto confirmaciones 
const colortxtconf = filteredMenuDataE.map(menuE => menuE.colorTxtConf);
//color del texto de tarjeta 
const colortxttrj = filteredMenuDataE.map(menuE => menuE.colorTxtTrj);
// tamaño de la fuente del tipo evento
const imgbkg1 = filteredMenuDataE.map(menuE => menuE.imgbkg1);
// tamaño de la fuente de las opciones del menu
const imgbkg2 = filteredMenuDataE.map(menuE => menuE.imgbkg2);
// url de la fuente
const fonturl1 = filteredMenuDataE.map(menuE => menuE.fontUrl1);
// nombre de la fuente
const fonturl2 = filteredMenuDataE.map(menuE => menuE.fontUrl2);
//filtro de imagen de fondo
const filtrocolorbkg1 = filteredMenuDataE.map(menuE => menuE.filtrocolorbkg1);

//console.log("evento en principal  ---------------> "+nombreTipoEvento );
useEffect(() => {
    const fetchData = () => {
      const url = `https://eventoservice2-7e2a65d34d88.herokuapp.com/Event/getevent?id_evento=${encodeURIComponent(idevento)}`;
      fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => response.json())
      .then(data => {
        setMenuDataE(data);
      })
      .catch(error => {
        console.error('Error al enviar la solicitud:', error);
        // Manejar el error aquí si es necesario
      });
    };
    if (idevento) {fetchData();}
}, [idevento]); //ejecutar fetchdata luego que se renderize y cada vez que idevento cambie


//-------------------------------------------------------------------------------------------
// funciones de lectura del EP SUBEVENTO
//-------------------------------------------------------------------------------------------
const [menuDataSE, setMenuDataSE] = useState([]); // Estado para almacenar los datos de menú
const menuArraySE = Object.values(menuDataSE); // Convierte el JSON a un array de valores
//const menuSE = menuDataSE;
const filteredMenuDataSE = [];

for (const key in menuDataSE) {
    if (menuDataSE.hasOwnProperty(key)) {
        const menuSE = menuDataSE[key];
        if (menuSE.id === menuSE.id) {
            filteredMenuDataSE.push(menuSE);
            const idSE = filteredMenuDataSE.map(menuSE => menuSE.id);

        }
    }
}


//filteredMenuDataSE.push(menuSE);
//const idSE = filteredMenuDataSE.map(menuSE => menuSE.id);
//console.log("evento en principal  ---------------> "+idSE );

useEffect(() => {
    const fetchData = () => {
      const urlSE = `https://eventoservice2-7e2a65d34d88.herokuapp.com/subeventos/getbyidsubevent?idevento=${encodeURIComponent(idevento)}`;
      fetch(urlSE, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => response.json())
      .then(data => {
        setMenuDataSE(data);
      })
      .catch(error => {
        console.error('Error al enviar la solicitud:', error);
        // Manejar el error aquí si es necesario
      });
    };
    if (idevento) {fetchData();}
}, [idevento]); //ejecutar fetchdata luego que se renderize y cada vez que idevento cambie


//---------------------------------------------------------------------------------
//--- Funcion de cambio de formato de fecha 
//---------------------------------------------------------------------------------
const FormateoFechaHora = (isofechahora) => {
    const timeZone = 'America/Argentina/Buenos_Aires'; // Ajustar según sea necesario
    const isoDate = isofechahora;
    const date = parseISO(isoDate);
    const zonedDate = toZonedTime(date, timeZone); // Convertir a la zona horaria deseada
    const formattedDate = format(zonedDate, "d 'de' MMMM 'de' yyyy", { locale: es });
    return formattedDate;
  };

  const FormateoSoloHora = (isofechahora) => {
    const timeZone = 'America/Argentina/Buenos_Aires'; // Ajustar según sea necesario
    const isoDate = isofechahora;
    const date = parseISO(isoDate);
    const zonedDate = toZonedTime(date, timeZone); // Convertir a la zona horaria deseada
    const formattedDate = format(zonedDate, "HH:mm", { locale: es });
    return formattedDate;
  };

//----------------------------------------------------------------------------------
// Codigo para cambiar la fuente 
//----------------------------------------------------------------------------------
const [fontUrl, setFontUrl] = useState('');
const changeFont = () => {
    const newFontUrl = fonturl1; // URL de la nueva fuente
    setFontUrl(newFontUrl);
    document.documentElement.style.setProperty('--dynamic-font-url', `url(${fonturl1})`);
};

useEffect(() => {
    
      const newStyle = document.createElement('style');
      newStyle.innerHTML = `
        @import url(${fonturl1});
       `;
      document.head.appendChild(newStyle);
      
  });

//----------------------------------------------------------------------------------
// Imagenes 
//----------------------------------------------------------------------------------

// aqui se definen los const de imagenes desde el repo 
// imagen de nombre de agasajados
const textoev1= `https://eplannerrec.s3.amazonaws.com/${encodeURIComponent(idevento)}_textoev1.gif`;
// imagen de tarjeta
const tarjeta = `https://eplannerrec.s3.amazonaws.com/${encodeURIComponent(idevento)}_tarjeta.png`;
// fondo de la pantalla principal
const imgbackground1 = `https://eplannerrec.s3.amazonaws.com/${encodeURIComponent(idevento)}_imgbackground1.png`;
// icon de tarejeta
const icontrj = `https://eplannerrec.s3.amazonaws.com/${encodeURIComponent(idevento)}_icon_trj.png`;
// icono de subevento
const iconsubevento = `https://eplannerrec.s3.amazonaws.com/${encodeURIComponent(idevento)}_icon_subevs.png`;
// icono de confirmacion
const iconconf = `https://eplannerrec.s3.amazonaws.com/${encodeURIComponent(idevento)}_icon_conf.png`;
// logo del evento que se muestra en watsapp
const iconwsp = `https://eplannerrec.s3.amazonaws.com/${encodeURIComponent(idevento)}_logo19_2_wsp.png`;


const [getcantic, setCanticValue] = useState('');
const [inputValue, setInputValue] = useState('');
const [cantidadInvitado, setCantidadInvitado] = useState(0);
const confirmaform = ()=>{
const confform = window.alert('Gracias por su confirmación !');
}
const confirmaCerrar = ()=>{
    const confirmacion = window.confirm('No olvide ENVIAR sus cambios seleccionando el botón "Enviar".\n ¿Desea salir a menú principal ?');
    if (confirmacion)   {
    setModal(!modal);
    window.location.href = `https://www.einvite.bar/index.html?idi=${encodeURIComponent(idinvitado)}`;
    }
}
//seleccion de la opcion de asistencia
const [selectedOption, setSelectedOption] = useState('');
const [defaultopt,setDefault]= useState('');          
const [showImage, setShowImage] = useState(false);
const handleButtonClick = () => {setShowImage(true);};
const handleClose = () => {setShowImage(false);};
const [modalIsOpen, setModalIsOpen] = useState(false);
const openModal = () => {setModalIsOpen(true);};
const closeModal = () => {setModalIsOpen(false);};
const handleShowImage = () => {setShowImage(true);};
let pageHeader = React.createRef();
//Modal de restriccion alimentaria
const [showModalRa, setShowModalRa] = useState(false);
const toggleMra = ()=>{
    setShowModalRa(!showModalRa);
}
//Modal de confiramcion
const [modal, setModal] = useState(false);
const toggle = () => {
//if (!modal) {window.alert('Celebración.\nEn el siguiente formulario usted Podrá :\nConfirmar su presencia.\nCambiar la cantidad de personas que asistirán.\nIndicar restricción alimentaria (botón "Dieta").\nRealize los cambios. Luego : \nSeleccione el botón "Enviar".\nSeleccione el botón "Cerrar".\n\nMuchas gracias.')};
    setModal(!modal);
}
const togglec = () => {
    confirmaCerrar();
}
const handleSubmit = (e) => {
e.preventDefault();
// Maneja el envío del formulario aquí
toggle();
};
//Modal de tarjeta
const [modaltj, setModaltj] = useState(false);
const toggletj = () => {
//window.location.href = `https://eventoapp-f43eebbd19a0.herokuapp.com/index.html?idi=${encodeURIComponent(idinvitado)}`;
setModaltj(!modaltj);
}
const handleSubmittj = (e) => {
e.preventDefault();
// Maneja el envío del formulario aquí
console.log("Formulario enviado");
toggletj();
};
//---------------------------------------------------------------
// visualiza oculta el formulario de prueba de confirmacion
// Define un estado para controlar la visibilidad del formulario
 const [showForm, setShowForm] = useState(false);
 // Función para alternar la visibilidad
 const toggleForm = () => {setShowForm(!showForm);};
//--------------------------------------------------------------

//Modal de tarjeta
const [modalay, setModalay] = useState(false);
const toggleay = () => {
//window.location.href = `https://eventoapp-f43eebbd19a0.herokuapp.com/index.html?idi=${encodeURIComponent(idinvitado)}`;
setModalay(!modalay);
}
const handleSubmitay = (e) => {
e.preventDefault();
// Maneja el envío del formulario aquí
console.log("Formulario enviado");
toggleay();
};
//Modal de lugar de evento
const [modalle, setModalle] = useState(false);
const togglele = () => setModalle(!modalle);
const handleSubmitle = (e) => {
e.preventDefault();
// Maneja el envío del formulario aquí
console.log("Formulario enviado");
togglele();
};
//Actualizar  invitados
const [idRestalim, setIdRestalim] = useState(null);
const [cantidad, setCantidad] = useState(null);
const [asiste, setAsiste] = useState(null);
const [execute, setExecute] = useState(false);

const [responseData, setResponseData] = useState(null);
const handleData2 = (data) => {
setResponseData(data);
setExecute(false); // Resetear después de la ejecución
};
const handleClick = () => {
//navigate('/');
setExecute(true);
confirmaform();
};
// -- inpunt 
const InputComponent = () => {const [inputValue, setInputValue] = useState('')};
const [canInvita, setCanInvita] = useState('');
const handleChange = (event) => {setCanInvita(event.target.value);};
var cinvc = cantidadInviConf;
const handleCantidadChange = (e) => {
const value = e.target.value;
if (
value.length <= 2 && 
value !== '0' && 
value.trim() !== '' && 
!isNaN(value) && 
parseInt(value, 10) >= 0 &&
parseInt(value, 10) <= cantidadInvitado2
) {
setCanInvita(e.target.value);
setCantidadInvitado(value);
setCanticValue(value);
cinvc = canInvita;
;
}
};






//--dietas 
const [restrictions, setRestrictions] = useState({
celiac: false,
vegan: false,
hypertensive: false,
});
//const valorDevuelto = 
<SelectedRestrictions restrictions={restrictions}/>
;
const valorDevuelto = ReactDOMServer.renderToString(
<SelectedRestrictions restrictions={restrictions} />
);
//---------------------------------
React.useEffect(() => {
    if (window.innerWidth > 991) {
        const updateScroll = () => {
            let windowScrollTop = window.pageYOffset / 3;
            pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
        };
        window.addEventListener("scroll", updateScroll);
        return function cleanup() {
            window.removeEventListener("scroll", updateScroll);
        };
    }
   
    
});
const confirmaprecString = confirmaprec.join(", ");
console.log("confirmaprecstring desde indexheader------>"+confirmaprecString);
console.log("selectedoption->"+selectedOption);
const myObjectString2 = JSON.stringify(idevento);






//abrir ventana de confirmacion
const navformconf = useNavigate();
const [params, setParams] = useState({
    confirmaprecstring: '',
    selectedoption: '',
    setSelectedoption:''
  });

  console.log("confirmaprecstring desde indexheader dentro veryder1->"+confirmaprecString);
// Función que verifica el valor de confirmaprecString
const verificarYRedirigir = () => {
   
    if (confirmaprecString !== "NO" && confirmaprecString !== "SI") {
        console.log("confirmaprecstring desde indexheader dentro veryder->"+confirmaprecString);
        navformconf('/') // Redirigir a index.html
    }
  };

  

const hanredformconf = () => {
     // Definir valores
     setParams({
        confirmaprecstring: confirmaprecString,
        selectedoption:selectedOption,
        setselectedoption:setSelectedOption
      });
      

      navformconf('/form-conf', { state: {
        fonturlinv:fonturl2.join(", "),
        nombreinvitado:nombreInvitado.join(", "),
        cantidadinviconf:cantidadInviConf.join(", "),
        cantidadinvitados:cantidadInvitado2.join(", "),
        confirmaprecstring:confirmaprec.join(", "), 
        selectedoption:selectedOption,
        setselectedoption:selectedOption,
        setdetalles:detalles,
        setidinvitado:idinvitado } });
        

};





  
return (
<>
<div className="page-header"> 
      {verificarYRedirigir()}
      <ObtenerInvitado handleData={handleData}  />
      
      {/*<CronGift targetHour={9} targetMinute={42} invitadoId={idinvitadotp} />*/}
      
      
      {/*
      <ObtenerInvitado handleData={handleData} />
      */}
      {/*style={{
      textalign:'center',
      background: 'rgba(188, 143, 143, 0.2)',
      For browsers that do not support gradients
      background:'-webkit-linear-gradient(90deg,rgba(188, 143, 143, 0.2),rgba(224, 23, 3, 0.6))',
      For Safari 5.1 to 6.0 
      background:'-o-linear-gradient(90deg,rgba(188, 143, 143, 0.2),rgba(224, 23, 3, 0.6))',
      For Opera 11.1 to 12.0 
      background: '-moz-linear-gradient(90deg,rgba(188, 143, 143, 0.2),rgba(224, 23, 3, 0.6))',
      For Firefox 3.6 to 15 
      background: 'linear-gradient(0deg,rgba(188, 143, 143, 0.2),rgba(224, 23, 3, 0.6))',
      Standard syntax
      }}*/}
      <Helmet>
          <meta property="og:title" content="E-Planner" />
          <meta property="og:description" content="Has recibido una invitacion !" />
          <meta property="og:image" content="logo19__2.png" />
          <meta property="og:url" content="https://einvite.bar/index.html" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Recibiste una invitación !" />
      </Helmet>
      <div className="page-header-image"  ref={pageHeader} 
      /*
      style={{
      display: 'flex',
      justifycontent: 'center',
      alignitems: 'center',
      textalign:'center',
      width: '100%',
      height: '100%', 
      }}*/
      style={{
      backgroundImage: `url(${imgbackground1})`
      }}
      >
      {/*
      <video autoPlay muted loop>
          <source src="/videos/novios.mp4" type="video/mp4" />
          Your browser does not support the video tag.
      </video>
      */}
    </div>
    {/*
    <div
    className="page-header-image"
    style={{
    backgroundImage: "url(" + require("assets/img/enamorados.jpg") + ")"
    }}
    ref={pageHeader}
    ></div>*/}
    <Container className="modalinvstyle1">

      <div className="content-center brand" >

          {changeFont}
          <h1 style = {{color: colortxtnomtev,fontSize:imgbkg1,fontFamily:fonturl2}}>{nombreTipoEvento}</h1>
          <h1 className="h1-seo" style={{ fontWeight: 'bold' }}>
          <img src={textoev1} style={{
          position :'relative',
          top : '3px',
          justifycontent: 'center',
          alignitems: 'center',
          textalign:'center',
          width: '70%',
          height: '70%', 
          }}  / >
          </h1>
          <div style={{ paddingTop: '4%'}}>
          <Row style={{justifyContent:'center',alignItems:'center'}} >
            <Col sm="2" md="3" lg="3">
                <h4 >
                    <a onClick={toggletj} target="_blank" style={{color:colortxttrj,fontSize:imgbkg2,fontFamily:fonturl2}}>
                        Tarjeta {" "}
                        <img
                        alt="..."
                        className="invision-logo"
                        src={icontrj}
                        ></img>
                    </a>
                    <div>
                        {/**********************************************************Modal de tarjeta*/}
                        <Modal isOpen={modaltj} toggle={toggletj} style={{
                            overlay: {
                            zIndex: '100'
                            },
                            content: {
                            height: '100%',
                            width: '100%',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            background: 'rgba(128, 128, 128, 0.1)',
                            flexDirection: 'column' // Distribuye los elementos en columna
                            },
                            }}>
                            <ModalBody className="d-flex flex-column align-items-center flex-grow-1">
                                <Form onClick={toggletj}>
                                    <CardPage imageUrl={tarjeta} />
                                </Form>
                            </ModalBody>
                            <ModalFooter style={{
                                display: 'flex',
                                justifyContent: 'left', // Centra horizontalmente
                                alignItems: 'left', // Centra verticalmente
                                width: '100%',
                                gap: '10px', // Espacio entre los botones (opcional)
                                zIndex: '101' // Asegura que el pie de página del modal esté en la capa superior
                                }}>
                                <Button color="secondary" onClick={toggletj}>
                                <img
                                        alt="..."
                                        src={require("assets/img/close.png")}
                                        style={{height:'20px',width:'20px'}}
                                        >
                                    </img>
                                </Button>
                                <Button color="secondary" >
                                    <img
                                        alt="..."
                                        src={require("assets/img/qr.png")}
                                        style={{height:'20px',width:'20px'}}
                                        >
                                    </img>
                                </Button>
                            </ModalFooter>      
                        </Modal>
                    </div>
                </h4>
            </Col>
            <Col sm="2" md="3" lg="3">
                <h4>
                    <a onClick={togglele} target="_blank" style={{color:colortxtsubevento, fontSize:imgbkg2,fontFamily:fonturl2}}>
                        Itinerario{" "}
                        <img
                            alt="..."
                            className="invision-logo"
                            src={iconsubevento}>
                        </img>
                    </a>
                    <div>
                       {/**********************************************************Modal de lugar de evento*/}
                        <Modal isOpen={modalle} toggle={togglele}style={{
                                overlay: {
                                zIndex: '100'
                                },
                                content: {
                                height: '100%',
                                width: '100%',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                flexDirection: 'column' // Distribuye los elementos en columna
                                },
                                }}>
                                <div 
                                    style = {{ 
                                    backgroundColor: 'gray',
                                    color : 'white',
                                    fontWeight: 'bold',
                                    display: 'grid',
                                    placeItems: 'center',
                                    height: '5%'
                                }}>
                                    Itinerario</div>

                                <Row style = {{paddingTop:'10px'}}>
                                
                                    {filteredMenuDataSE.map(menuSE => (
                                    
                                        <div class="card">
                                            <div className="card-body" style = {{
                                                backgroundImage: `url(${menuSE.urlImg})`,
                                                backgroundsize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundposition: 'center',
                                                
                                                position: 'relative'
                                                }}>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        background: 'gray',
                                                        color : 'white',
                                                        borderRadius: '40%',
                                                        width: '60px',
                                                        height: '60px',
                                                        zIndex: '110',
                                                        display: 'flex',              // Flexbox para centrar
                                                        justifyContent: 'center',     // Centra horizontalmente
                                                        alignItems: 'center',          // Centra verticalmente
                                                        top: '13px',
                                                        border: '6px solid white',
                                                        left : '-10px',
                                                        fontWeight: 'bold'
                                                    }}
                                                >

                                                    
                                                    &nbsp;{menuSE.id}
                                                </div>
                                                <div className="card-title"
                                                    style={{
                                                        position : 'relative',
                                                        fontSize: '1.5em',
                                                        borderRadius: '20px',
                                                        backgroundColor: 'rgba(60,60,60,0.5)',
                                                        display: 'flex',              // Usar flexbox
                                                        justifyContent: 'center',     // Centrar horizontalmente
                                                        alignItems: 'center',         // Centrar verticalmente
                                                        textAlign: 'center',          // Centrar el texto
                                                        color: 'white',
                                                        height: '30%'
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;{menuSE.titulo}
                                                </div>
                                                <div className="card-text" style={{fontSize:'1.0em'}}>
                                                        <strong> 
                                                            <img
                                                                src={require("assets/img/date.png")}
                                                                style={{height:'20px',width:'20px'}}
                                                            >
                                                            </img>&nbsp;&nbsp;Fecha : {FormateoFechaHora(menuSE.fecha)}
                                                        </strong>
                                                    <br />
                                                    <strong> 
                                                            <img
                                                                src={require("assets/img/time.png")}
                                                                style={{height:'20px',width:'20px'}}
                                                            >
                                                            </img>&nbsp;&nbsp;Hora : {FormateoSoloHora(menuSE.fecha)}
                                                        </strong>
                                                    <br />
                                                        <strong>
                                                            <img
                                                                src={require("assets/img/dir.png")}
                                                                style={{height:'20px',width:'20px'}}
                                                            >
                                                            </img>
                                                            &nbsp;&nbsp;Lugar :{menuSE.lugar}</strong>
                                                    <br />
                                                        <strong>
                                                            <img
                                                                src={require("assets/img/googlemaps.png")}
                                                                style={{height:'20px',width:'20px'}}
                                                            >
                                                            </img>
                                                        &nbsp;&nbsp;Dirección :{menuSE.direccion}</strong> 
                                                </div>
                                                <a href="#" className="btn btn-primary " onClick={() => confirmarYAbrirEnlaceGoogleMaps2(menuSE.latitud, menuSE.longitud)}><i className="bi bi-geo-alt-fill" style={{color:'white'}}>&nbsp;Cómo llegar</i></a>
                                            </div>
                                        </div>
                                    
                                    ))}
                                </Row>
                                    <ModalFooter style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Centra horizontalmente
                                    alignItems: 'center', // Centra verticalmente
                                    width: '100%',
                                    
                                    zIndex: '1000'
                                    }}>
                                    <button onClick={togglele} style={{width : '50%' , backgroundColor : 'gray', color :'white'}}> 
                                   Cerrar
                                    </button>
                                    </ModalFooter>                       
                        </Modal>           
                    </div>
                    
                    
                </h4>
            </Col>
                  <Col sm="2" md="3" lg="3">
                  <h4>
                  {/*<a onClick={toggle} target="_blank" style={{color:colortxtconf,fontSize:imgbkg2,fontFamily:fonturl2}}>*/}
                  <a onClick={hanredformconf} target="_blank" style={{color:colortxtconf,fontSize:imgbkg2,fontFamily:fonturl2}}>
                  Confirmar {" "}
                  <img
                      alt="..."
                      className="invision-logo"
                      //src={require("assets/img/checkman2.png")}
                      src = {iconconf}
                      ></img>
                  </a>
                  
                      {/********************************************************Modal de confirmacion
                      <div className="d-flex justify-content-center align-items-center vh-100"
                      style={{ flexDirection: "column" }}>
                         {showForm && <FormConf />} 
                      </div>*/}
      </h4>
      </Col>
      </Row>
      </div>
      </div>
    </Container>
    {/*<ObtenerEvento handleDataE={handleDataE} idevento = {idevento}/>*/}
   
</div>
</>
);
}
export default IndexHeader;
