import React from 'react';

function SelectedRestrictions({ restrictions }) {
  let variable = '';

  if (restrictions.celiac) variable += '1';
  if (restrictions.vegan) variable += '2';
  if (restrictions.hypertensive) variable += '3';

  return (
    
    variable
    
  );
}

export default SelectedRestrictions;
