import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './NewNavBar.js'
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink
} from 'mdb-react-ui-kit';

import ObtenerInvitado from 'components/Headers/EPEvento.js';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
function IndexNavbar() {

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
        //setNavbarColor("gray");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        ></div>
        
      ) : null}
      <Navbar className={"fixed-top"} style = {{background: 'rgba(175, 122, 197 )',zIndex:'50'}}>
        <Container  >
          <div className="navbar-translate"  >
              <div>
                  
              <MDBNavbar light style={{ border: 'none', boxShadow: 'none' }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href='#' >
            <img
              src={require("assets/img/einvlogo2.png")}
              height='30'
              alt=''
              loading='lazy'
              
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Mariela Gabaldo</strong>&nbsp;&nbsp;&nbsp;&nbsp;Event planner
          </MDBNavbarBrand>
          
        </MDBContainer>
        
      </MDBNavbar>
                  
              </div>
          </div>
        </Container>
      </Navbar>
      
    </>
  );
}

export default IndexNavbar;
