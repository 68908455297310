// FullScreenImage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'assets/css/FullScreenImage.css';
import {
    Button
  } from "reactstrap";

const CardPage = ({ imageUrl}) => {

  return (
    <div>
    <div className="fullscreen-image">
      
        <img src={imageUrl} alt="" />
        </div>
      
    </div>
    
    
  );
}

export default CardPage;