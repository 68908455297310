import React, { useState, useEffect } from 'react';
import { Modal,Form, ModalBody } from 'reactstrap';

const ScheduledAlert = ({ targetHour, targetMinute, invitadoId }) => {
  const [showModal, setShowModal] = useState(false); // Estado para mostrar/ocultar la modal

  useEffect(() => {
    const checkTimeAndSetAlert = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      
      // Evaluar si la hora actual coincide con la hora objetivo
      if (currentHour === targetHour && currentMinute === targetMinute) {
        if (invitadoId === 'e55ce5f3-1aca-4895-b953-3687485d1df9') {
          setShowModal(true);
         
        }
      } else {
        setShowModal(false);
      }
    };

    // Chequear cada minuto
    const intervalId = setInterval(checkTimeAndSetAlert, 60000);

    // Ejecutar inmediatamente para no esperar hasta el próximo minuto
    checkTimeAndSetAlert();

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [targetHour, targetMinute, invitadoId]);

  return (
    <>
    
      <Modal isOpen={showModal}
      >
        <ModalBody className="d-flex flex-column align-items-center flex-grow-1" style={{flexDirection: 'column',backgroundColor:'blue',backgroundImage: 'url(' + require('assets/img/wavebg.png') + ')',backgroundRepeat:'no-repeat'}}>
                        <Form style={{flexDirection: 'column',backgroundImage: 'url(' + require('assets/img/confetti.gif') + ')'}}>
                          <img
                                alt="..."
                                src={require("assets/img/regaloon.gif")}
                                style={{height:'100%',width:'100%'}}
                                >
                            </img>
                        </Form>
                    </ModalBody>
      </Modal>

      
      {/* Contenido principal del componente */}
      
    </>
  );
};

export default ScheduledAlert;
