import React, { useEffect, useState } from 'react';

function NombreInvURL() {
  const [textFromURL, setTextFromURL] = useState('');

  useEffect(() => {
    // Obtener la búsqueda de la URL actual
    const searchParams = new URLSearchParams(window.location.search);
    // Obtener el valor del parámetro 'texto' de la URL
    const textoParamIde = searchParams.get('ide');
    if (textoParamIde) {
      // Codificar el valor del texto para asegurar que sea válido en la URL
      const encodedIde = encodeURIComponent(textoParamIde);
      // Redirigir a index.html con el texto como parámetro
      window.location.href = `/index.html?ide=${encodedIde}`;
    }
    if (textoParamIde) {
      // Actualizar el estado con el valor del parámetro 'texto'
      setTextFromURL(textoParamIde);
    }

  }, []);

  return (
   null
  );
}

export default NombreInvURL;
