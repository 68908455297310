import React, { useState } from 'react';
import { Link } from "react-router-dom";

import ObtenerInvitado from 'components/Headers/EPEvento.js';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
function IndexNavbar() {

  // funciones de lectura del ep
  const [menuData, setMenuData] = useState([]); // Estado para almacenar los datos de menú
  // Función para pasar los datos de menú recibidos a este componente y almacenarlos en el estado
  const handleData = (data) => {setMenuData(data);};
  //const filteredMenuData = menuData.filter(menu => menu.id === menu.id);
  const menuArray = Object.values(menuData); // Convierte el JSON a un array de valores
  //const filteredMenuData = menuArray.filter(menu => menu.id === menu.id);
  const filteredMenuData = [];
  for (const key in menuData) {
    if (menuData.hasOwnProperty(key)) {
      const menu = menuData[key];
      if (menu.id === menu.id) {
        filteredMenuData.push(menu);
      }
    }
  }
  const idinvitado = filteredMenuData.map(menu => menu.id);
  


  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
        //setNavbarColor("gray");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <ObtenerInvitado handleData={handleData} />
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        ></div>
        
      ) : null}
      <Navbar className={"fixed-top"} style = {{background: 'rgba(128, 128, 128, 0.5)',height:'5%',zIndex:'50'}}>
        <Container  >
          <div className="navbar-translate"  >
              <div style ={{display:'flex',alignitems: 'center'}} >
                      <NavbarBrand
                          //href="https://www.google.com"
                          target="_blank"
                          id="navbar-brand"
                          style = {{color:'gainsboro'}}
                          href={`https://einvite.bar/index.html?idi=${encodeURIComponent(idinvitado)}`}
                        >
                          <div style = {{height:'40px',width:'40px',paddingLeft:'5px',paddingRight:'10px'}}><img src="/home.png"/></div>
              
                      </NavbarBrand>
                      <NavbarBrand
                          //href="https://www.google.com"
                          target="_blank"
                          id="navbar-brand"
                          style = {{color:'gainsboro'}}
                        >
                          <div style = {{height:'50px',width:'50px',paddingLeft:'10px',paddingRight:'10px'}}><img src="/logomg.png"/></div>
              
                      </NavbarBrand>
                          <UncontrolledTooltip target="#navbar-brand">
                                MG eventos 3774 54-8047
                          </UncontrolledTooltip>
                  
                      {/*Regalito */}
                      <NavbarBrand
                          target="_blank"
                          id="navbar-brand"
                          style = {{color:'gainsboro'}}
                      >
                          <div style = {{height:'50px',width:'50px',paddingLeft:'10px',paddingRight:'10px'}}><img src={require("assets/img/regalooff.png")}/></div>
                      </NavbarBrand>
                  <div style={{ position: 'absolute', right: '10px', height: '50px', width: '50px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '6px' }}>

                      <NavLink
                      href="https://web.facebook.com/p/Mariela-Gabaldo-Eventos-100039658608199/?locale=es_LA&_rdc=1&_rdr"
                      target="_blank"
                      id="facebook-tooltip"
                      >
                      <i className="fab fa-facebook-square"></i>
                      <p className="d-lg-none d-xl-none"></p>
                      </NavLink>

                  </div>
                  <div style={{ position: 'absolute', right: '40px', height: '50px', width: '50px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '6px' }}>


                      <NavLink
                      href="https://www.instagram.com/Marielagabaldo"
                      target="_blank"
                      id="instagram-tooltip"
                      >
                      <i className="fab fa-instagram"></i>
                      <p className="d-lg-none d-xl-none"></p>
                      </NavLink>

                  </div>
              </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
