import React from "react";
import { Container, Row,Col } from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbarDf";
import 'assets/css/fuentes.css'

const Defaultpg = () => {
  return (
    <div>
      <div style={{ position :'absolute',width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IndexNavbar />
      </div>
      <div style = {{position:'relative',top:'130px'}}>
      <Container>
      
      <div>
        
        <Row>
          <Col>
            <Row>
              {/*Card principal explicativo */}
              <div className="card" style={{left: '50%', transform: 'translateX(-50%)', maxWidth: '90vw' }}>
                    <div className="card-header" style = {{fontSize:'2.0em',fontWeight:'bold',justifyContent: 'center', alignItems: 'center',textAlign:'center'}}>
                    Invitaciones online para eventos
                    </div>
                    <div className="card-body">
                      <h5 className="card-title" 
                        style = {{fontWeight:'bold',justifyContent: 'center', alignItems: 'center',textAlign:'center'}}>
                          Planificá tu evento y envía las invitaciones de forma online
                      </h5>
                      <div clasNames="card-text" 
                        style = {{fontWeight:'bold',justifyContent: 'center', alignItems: 'center',textAlign:'center'}}>
                          Contactanos al :  
                          <div style = {{display: 'flex',fontWeight:'bold',justifyContent: 'center', alignItems: 'center',textAlign:'center'}}>    
                            <img src={require("assets/img/wsplogo.png")} style = {{height:'30px',width : '30px'}}/>
                            <div style = {{fontSize:'1.5em',fontWeight:'bold',color:'darkgreen'}}>&nbsp;+543774636266</div>
                          </div>
                      </div>
                      {/*<a href="#" className="btn btn-primary">Go somewhere</a>*/}
                    </div>
              </div>
            </Row>
            <Row>
              <div className="card mb-3" style={{
                left: '50%', 
                transform: 'translateX(-50%)', 
                maxWidth: '90vw',
                backgroundColor: 'transparent',
                border: 'none', 
                boxShadow: 'none'
                }}>
                <img 
                  src={require("assets/img/imagepromo1.png")} 
                  className="card-img-top" 
                  alt="Card image" 
                  style={{ 
                    maxWidth: '100%', 
                    height: 'auto'
                  }} 
                />
                <div className="card-body">
                  <h5 className="card-title">Solución innovadora para tu evento</h5>
                  <div className="card-text">
                    Te proponemos una solución innovadora , simple y sencilla para que tu evento destaque
                  </div>
                  <div className="card-text">
                    <small className="text-muted"></small>
                  </div>
                </div>
              </div>
            </Row>
            {/*Tarjetitas*/}
            <Row className="justify-content-center" style={{alignItems: 'center',textAlign:'center'}}>
                <Col md={4}>
                <div className="card" style={{ 
                  width: '100%', 
                  backgroundColor: 'transparent', 
                  border: 'none', 
                  boxShadow: 'none',
                  fontFamily:'Dancing Script'
                  
                  }}>
                  <img src={require("assets/img/cumples.png")} className="card-img-top" alt="Cumpleaños" />
                  <div className="card-body">
                    <div className="card-text" style={{fontSize:'2.0em'}}>Cumpleaños</div>
                  </div>
                </div>
              </Col>
                <Col md={4}>
                <div className="card" style={{ 
                  width: '100%', 
                  backgroundColor: 'transparent', 
                  border: 'none', 
                  boxShadow: 'none',
                  fontFamily:'Dancing Script'
                  }}>
                    <img src={require("assets/img/bodas.png")} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="card-text" style={{fontSize:'2.0em'}} >Bodas</div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                <div className="card" style={{ 
                  width: '100%', 
                  backgroundColor: 'transparent', 
                  border: 'none', 
                  boxShadow: 'none',
                  fontFamily:'Dancing Script'
                  }}>
                    <img src={require("assets/img/fiestapromo.png")} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="card-text" style={{fontSize:'2.0em'}}>Fiestas</div>
                    </div>
                  </div>
                </Col>
            </Row>
            {/*Card texto + imagen */}
            
            

          </Col>
        </Row>
      </div>
      </Container>
    </div>
    </div>
  );
};

export default Defaultpg;


