//import React from "react";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PadreDelRadioButton from "./PadreDelRadioButton";
import ObtenerInvitado from 'components/Headers/EPEvento.js';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col
} from "reactstrap";

// core components
//import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
//import TransparentFooter from "components/Footers/TransparentFooter.js";
import RadioButton from './RadioButton'; // Ajusta la ruta según donde esté tu componente RadioButton
import CheckDietas from './CheckDietas';

function LoginPage() {

  // funciones de lectura del ep
  const [menuData, setMenuData] = useState([]); // Estado para almacenar los datos de menú
  // Función para pasar los datos de menú recibidos a este componente y almacenarlos en el estado
  const handleData = (data) => {setMenuData(data);};
  //const filteredMenuData = menuData.filter(menu => menu.id === menu.id);
  const menuArray = Object.values(menuData); // Convierte el JSON a un array de valores
  //const filteredMenuData = menuArray.filter(menu => menu.id === menu.id);
  const filteredMenuData = [];
  for (const key in menuData) {
    if (menuData.hasOwnProperty(key)) {
      const menu = menuData[key];
      if (menu.id === menu.id) {
        filteredMenuData.push(menu);
      }
    }
  }



  
  const [selectedOption, setSelectedOption] = useState('option1');
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const navigate = useNavigate();

  const idevento = filteredMenuData.map(menu => menu.idEvento);
  const idinvitado = filteredMenuData.map(menu => menu.id);

  const handleCancelClick = () => {
    // Navegar a la página principal (index)
    window.location.href = `http://localhost:3000/index.html?ide=${idevento}&idi=${idinvitado}`;
  };

  
  
  const nombreInvitado = filteredMenuData.map(menu => menu.nombreInvitado);


  React.useEffect(() => {
    
   
    
    document.body.classList.add("login-page");
    //document.body.classList.add("sidebar-collapse");
    //document.documentElement.classList.remove("nav-open");
    //window.scrollTo(0, 0);
    //document.body.scrollTop = 0;
    return function cleanup() {
      //document.body.classList.remove("login-page");
      //document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      
      <div className="page-header clear-filter" filter-color="blue">

        
      <ObtenerInvitado handleData={handleData} />
     

        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bgconfirma.png") + ")"
          }}
        ></div>

        
        <div>
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form action="" className="form" method="">

                  <CardBody>
                    Confirmo mi prescencia :
                    <PadreDelRadioButton selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                    <p>{selectedOption}</p>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={nombreInvitado}

                        type="text"
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                      >

                      


                      </Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons design_bullet-list-67"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="number"
                        placeholder="Cantidad"
                        inputMode="numeric"
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                      ></Input>
                    </InputGroup>
                    Restricciones alimentarias
                    <CheckDietas />
                    <div>
                  <Button
                      block
                      className="btn-round"
                      color="info"
                      //href="#ang"
                      onClick={(e) => e.preventDefault()}
                      size="lg"
                    >
                      Enviar
                    </Button>
                    <Button
                      block
                      className="btn-round"
                      color="warning"
                      //href="#ang"
                      size="lg"
                      onClick={handleCancelClick}
                    >
                      Cancelar
                    </Button>
                    </div>
                  </CardBody>
                  <CardFooter className="text-center" >
                   
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        
      </div>
    </>
  );
}

export default LoginPage;
