// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@font-face {
  font-family: "DynamicFont";
  src: var(--dynamic-font-url);
}
@font-face {
  font-family: 'Dancing Script', cursive;
  src: url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap") ;
}
  `, "",{"version":3,"sources":["webpack://./src/assets/css/fuentes.css"],"names":[],"mappings":";;AAEA;EACE,0BAA0B;EAC1B,4BAA4B;AAC9B;AACA;EACE,sCAAsC;EACtC,+FAA+F;AACjG","sourcesContent":["\n\n@font-face {\n  font-family: \"DynamicFont\";\n  src: var(--dynamic-font-url);\n}\n@font-face {\n  font-family: 'Dancing Script', cursive;\n  src: url(\"https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap\") ;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
