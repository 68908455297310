import React from 'react';
import RadioButton from './RadioButton';
//import RadioButton from "react-bootstrap-switch";

function PadreDelRadioButton({ opcionbd,selectedOption}) {
  return (
    <div>
      <RadioButton  opcionbd={opcionbd} selectedOption={selectedOption}/>
      {/*<p>{selectedOption}</p>*/}
    </div>
  );
}

export default PadreDelRadioButton;

