// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .radio-input {
    display: none; /* Oculta los radio buttons */
  }
  
  .button-label {
    flex: 1 1;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    color: black;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .button-label.active-no {
    background-color: gray;
    color: white;
  }
  
  .button-label.active-si {
    background-color: green;
    color: white;
  }
  
  @media (max-width: 768px) {
    .button-label {
      font-size: 16px;
      padding: 8px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/SwComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,aAAa,EAAE,6BAA6B;EAC9C;;EAEA;IACE,SAAO;IACP,aAAa;IACb,eAAe;IACf,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,uDAAuD;EACzD;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,uBAAuB;IACvB,YAAY;EACd;;EAEA;IACE;MACE,eAAe;MACf,YAAY;IACd;EACF","sourcesContent":[".button-container {\n    display: flex;\n    justify-content: center;\n    gap: 10px;\n  }\n  \n  .radio-input {\n    display: none; /* Oculta los radio buttons */\n  }\n  \n  .button-label {\n    flex: 1;\n    padding: 10px;\n    font-size: 18px;\n    cursor: pointer;\n    border: none;\n    border-radius: 5px;\n    background-color: #f0f0f0;\n    color: black;\n    text-align: center;\n    transition: background-color 0.3s ease, color 0.3s ease;\n  }\n  \n  .button-label.active-no {\n    background-color: gray;\n    color: white;\n  }\n  \n  .button-label.active-si {\n    background-color: green;\n    color: white;\n  }\n  \n  @media (max-width: 768px) {\n    .button-label {\n      font-size: 16px;\n      padding: 8px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
