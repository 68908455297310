
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import Default from "views/default.js";
import NucleoIcons from "views/NucleoIcons.js";
import LoginPage from "views/examples/LoginPage.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import NombreInvURL from "views/examples/NombreInvURL.js";
import FormConf from "views/examples/FormConf.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      {/* Define tus rutas aquí */}
      <Route path="/" element={<Default />} />
      <Route path="/index.html" element={<Index />} />
      {/*<Route path="/nucleo-icons" element={<NucleoIcons />} />
      <Route path="/landing-page" element={<LandingPage />} />
      <Route path="/profile-page" element={<ProfilePage />} />
      <Route path="/login-page" element={<LoginPage />} />*/}
      <Route path="/form-conf" element={<FormConf />} />
      <Route path="/invite" element={<NombreInvURL />} />
      
      {/* Redirige cualquier otra ruta no especificada hacia /index.html */}
      <Route path="*" element={<Navigate to="/index.html" />} />
      
        
    </Routes>
  </BrowserRouter>
);
