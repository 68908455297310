// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* IncrementDecrementBtn.css */

.btn-group {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #F5F8FB;
    transition: border 0.3s ease;
  }
  
  .btn-group:hover {
      border: 1px solid #4097fe
  }
  
  button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4097fe;
    background-color: #fff;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  button:hover {
      background-color: #f3f9ff;
  }
  
  p {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #F5F8FB;
    border-right: 1px solid #F5F8FB;
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/IncDecBtn.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,4BAA4B;EAC9B;;EAEA;MACI;EACJ;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,yBAAyB;EAC3B;;EAEA;MACI,yBAAyB;EAC7B;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,8BAA8B;IAC9B,+BAA+B;EACjC","sourcesContent":["/* IncrementDecrementBtn.css */\n\n.btn-group {\n    display: flex;\n    align-items: center;\n    background-color: #fff;\n    border-radius: 8px;\n    overflow: hidden;\n    border: 1px solid #F5F8FB;\n    transition: border 0.3s ease;\n  }\n  \n  .btn-group:hover {\n      border: 1px solid #4097fe\n  }\n  \n  button {\n    width: 50px;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #4097fe;\n    background-color: #fff;\n    border: none;\n    cursor: pointer;\n    transition: all 0.3s ease;\n  }\n  \n  button:hover {\n      background-color: #f3f9ff;\n  }\n  \n  p {\n    width: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-left: 1px solid #F5F8FB;\n    border-right: 1px solid #F5F8FB;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
