import React, { useEffect,useState } from 'react';
import Switch from 'react-bootstrap-switch';
// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


function SwitchToggle({ opcionbd, selectedOption}) {
  const [selectedoption, setSelectedOption] = useState(selectedOption);
  useEffect(() => {
    setSelectedOption(opcionbd);
  }, [opcionbd, setSelectedOption]);

  const handleOptionChange = (el, state) => {
    setSelectedOption(state ? 'SI' : 'NO');
  };

  console.log("---|||---|||--->" + opcionbd);
  console.log("Selected Option: " + selectedoption);

  return (
    <div className="d-flex justify-content-center align-items-center" >
      <Row>
      <Col lg="4" sm="6">
      <Switch
        offColor="black"
        onColor="green" 
        onText="SI"
        offText="NO"
        value={selectedoption === 'SI'}
        onChange={handleOptionChange}
  
    
      />
      </Col>
      </Row>
    </div>
  );
}

export default SwitchToggle;
